import { Button } from "@chakra-ui/react";
import { Box, Flex, Spacer, Text } from "@chakra-ui/layout";
import { Table, Tbody, Td, Tr } from "@chakra-ui/table";
import ShareIcon from "../Icon/ShareIcon"
import { useRef, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const BgRank = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="32" viewBox="0 0 30 32" fill="none">
        <path d="M1 4C1 2.34314 2.34314 1 4 1H26C27.6569 1 29 2.34315 29 4V26.8075C29 28.2181 28.025 29.4249 26.6731 29.6927C17.9554 31.4196 12.02 31.4491 3.31319 29.7011C1.96844 29.4312 1 28.228 1 26.8219V4Z" fill="url(#paint0_linear_326_3048)" stroke="#F6AC37" stroke-width="2"/>
        <defs>
            <linearGradient id="paint0_linear_326_3048" x1="15" y1="-1.49012e-06" x2="15" y2="32" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="1" stop-color="#FFE4BA" stop-opacity="0.4"/>
            </linearGradient>
        </defs>
        </svg>
    )
}

export const BgRankSolid = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="32" viewBox="0 0 30 32" fill="none">
        <path d="M0 4C0 1.79086 1.79086 0 4 0H26C28.2091 0 30 1.79086 30 4V26.8075C30 28.6792 28.7034 30.31 26.8674 30.6737C18.024 32.4254 11.9556 32.4561 3.11637 30.6816C1.28774 30.3145 0 28.6871 0 26.8219V4Z" fill="#FFF9F0"/>
        </svg>
    )
}

const Arrow = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9" fill="none">
        <path d="M7.64501 4.04635L4.14501 1.54635C4.10267 1.5162 4.05198 1.5 4.00001 1.5C3.94803 1.5 3.89735 1.5162 3.85501 1.54635L0.355005 4.04635C0.322523 4.06947 0.296044 4.10003 0.277779 4.13548C0.259513 4.17092 0.24999 4.21022 0.250005 4.2501V6.5776C0.249708 6.62274 0.26164 6.66712 0.284535 6.70603C0.307429 6.74493 0.340432 6.77692 0.380038 6.79858C0.419645 6.82024 0.464379 6.83077 0.50949 6.82906C0.554601 6.82735 0.598406 6.81345 0.636256 6.78885L4.00001 4.5951L7.36376 6.78885C7.40161 6.81345 7.44541 6.82735 7.49052 6.82906C7.53563 6.83077 7.58037 6.82024 7.61997 6.79858C7.65958 6.77692 7.69258 6.74493 7.71548 6.70603C7.73837 6.66712 7.7503 6.62274 7.75001 6.5776V4.2501C7.75002 4.21022 7.7405 4.17092 7.72223 4.13548C7.70397 4.10003 7.67749 4.06947 7.64501 4.04635Z" fill="#F6AC37"/>
        </svg>
    )
}

const Handle = (attribute) => {
    
    return (
        <Box {...attribute} >
            <Box position="relative">
                <Box w="109px" h="15px" display="flex" >
                    <Box w="100px" h="20px" position="absolute" left="-5px" top="-2px">
                        <Box borderRight="10px solid #FFDAA3" borderTop="10px solid transparent" borderBottom="10px solid transparent" float="left"></Box>
                        <Box w="74px" h="20px" bg="#FFDAA3" float="left"></Box>
                        <Box borderLeft="10px solid #FFDAA3" borderTop="10px solid transparent" borderBottom="10px solid transparent" float="left"></Box>
                    </Box>
                    <Box w="83px" position="relative" display="flex" alignItems="center" justifyContent="center">
                        <Box position="absolute"><Arrow /></Box>
                        <svg xmlns="http://www.w3.org/2000/svg" width="83" height="15" viewBox="0 0 83 15" fill="none">
                        <path d="M4.80271 1.89683C5.56264 1.01025 6.67204 0.5 7.83974 0.5H75.1603C76.328 0.5 77.4374 1.01025 78.1973 1.89683L82.4422 6.84921C82.7632 7.2237 82.7632 7.7763 82.4422 8.15079L78.1973 13.1032C77.4374 13.9897 76.328 14.5 75.1603 14.5H7.83974C6.67204 14.5 5.56264 13.9897 4.80271 13.1032L0.557821 8.15079C0.23683 7.7763 0.23683 7.2237 0.557821 6.84921L4.80271 1.89683Z" fill="#404042"/>
                        </svg>
                    </Box>
                    
                </Box>
            </Box>
        </Box>
    )
}

const RankSlide = ({player, data }) => {
    const slideContainerRef = useRef();
    const location = useLocation();
    const [slideUp, setSlideUp] = useState(false);
    const handleClickHandle = (e) => {
        if (slideUp)
        {
            slideContainerRef.current.style.top = '50%';
            slideContainerRef.current.style.height = '50vh';
            setSlideUp(false)
        }
        else {
            slideContainerRef.current.style.top = '64px';
            slideContainerRef.current.style.height = '100vh';
            setSlideUp(true)
        }
    }

    const share = async () => {
        const shareData = {
            title: "RRQ Trivia Result",
            text: "RRQ Trivia Result",
            url: `${process.env.REACT_APP_QUIZ_DOMAIN}${location.pathname}${location.search}`,
          };
        await navigator.share(shareData);
    }

    return (
        <Box className="animate" position="absolute" h="50vh" w="100%" top="50%" bg="white" borderTopRadius={24} ref={slideContainerRef} zIndex={100}>
            <Box h="78px" display="flex" alignItems="center" borderRadius={16} bg="#FFDAA3" w="100%" alignContent="center" justifyContent="center" >
                <Handle position="absolute" top="-8px" left="calc(50% - 47px)" onClick={(e) => handleClickHandle(e)} />
                {player && (

                <Table variant="simple" overflow="auto">
                    <Tbody>
                        <Tr>
                            <Td width="50px" paddingLeft="10px" paddingRight="15px" >
                                <Box w="50px" position="relative" display="flex" align="center" alignItems="center" justifyContent="center">
                                    <BgRank />
                                    <Text fontFamily="Candal" position="absolute" fontSize="12px" color="#212121">{player?.rank}</Text>
                                </Box>
                            </Td>
                            <Td paddingLeft="0px" paddingRight="14px" paddingY="8px">
                                <Flex width="100%" display="inline-flex" paddingY="17px" paddingRight="12px"  >
                                    <Box align="left" alignItems="center" justifyContent="left" display="flex" color="#212121" fontSize="16px" fontWeight="800" textAlign="left">
                                        {player.name}
                                    </Box>
                                    <Spacer />
                                    <Box w="70px" display="flex" padding="8px" align="center" alignItems="center" justifyContent="center" borderRadius={8} bg="white">
                                        <Text fontSize="12px" color="#212121" fontWeight="1000">
                                            {player.score} pt
                                        </Text>
                                    </Box>
                                </Flex>
                            </Td>
                        </Tr>                
                    </Tbody>
                </Table>
                )}
            </Box>
            
            <Box mx="10px" overflow="auto" h="calc(100% - 78px)"  >
                <RankTable sortedData={data} />
            </Box>
            <Box w="100%" onClick={e => share()} maxW="500px" zIndex={200} position="fixed" h="100px" top="calc(100% - 70px)" bg="linear-gradient(0deg, #FFF 49.06%, rgba(255, 255, 255, 0.00) 100%)">
                <Button leftIcon={<ShareIcon />} bg="orange" boxShadow="0px 4px 0px 0 #E8891C" fontSize="12px" fontFamily="Candal" variant="solid"
                    _active={{
                        background: "orange",
                        boxShadow: "0px 4px 0px 0 #E8891C !important"
                    }}
                    _hover={{
                        background: "orange",
                        boxShadow: "0px 4px 0px 0 #E8891C !important"
                    }}
                >
                    Bagikan
                </Button>
            </Box>
        </Box>
    )
}

const RankTable = ({sortedData}) => {
    return (
        <Table variant="simple" overflow="auto">
          <Tbody>
            {sortedData?.map((player) => (
              <Tr key={player.id}>
                <Td width="50px" paddingLeft="10px" paddingRight="15px" >
                    <Box w="100%" position="relative" display="flex" align="center" alignItems="center" justifyContent="center">
                        <BgRankSolid />
                        <Text color="#212121" position="absolute" fontSize="12px" letterSpacing="-0.72px" fontWeight="400" fontFamily="Candal">{player.rank}</Text>
                    </Box>
                </Td>
                <Td paddingLeft="0px" paddingRight="14px" paddingY="8px">
                    <Flex borderRadius="8px" width="100%" display="inline-flex" paddingY="17px" paddingX="12px" background="#FFF9F0" >
                        <Box color="#212121" fontSize="16px" fontWeight="600">{player.name}</Box>
                        <Spacer />
                        <Box color="#86868B" fontSize="12px" fontWeight="1000" >{player.score} pt</Box>
                    </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      );
}

export default RankSlide;