import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Suspense, lazy } from "react";
import CreateRoom from "./pages/CreateRoom";
import Result from "./pages/Result";

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const GameRoom = lazy(() => import("./pages/GameRoom"));
const Home = lazy(() => import("./pages/Home"));
const Test = lazy(() => import("./pages/Test"));

const ENDPOINT = process.env.REACT_APP_ENDPOINT;

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Suspense fallback={<div />}>
          <Route exact path="/room/:id">
            <GameRoom endpoint={ENDPOINT} />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/c">
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
              <CreateRoom />
            </GoogleReCaptchaProvider>
          </Route>
          <Route exact path="/test">
            <Test />
          </Route>
          <Route exact path="/result">
            <Result />
          </Route>
        </Suspense>
      </Switch>
    </Router>
  );
};

export default Routes;
